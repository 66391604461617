import http from "../utils/http";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
const resquest = `${process.env.VUE_APP_ERP_BASE_URL}`;
const resquest1 = `${process.env.VUE_APP_VIP_BASE_URL}`;
const resquest2 = `${process.env.VUE_APP_TRMS_BASE_URL}`;
// get请求
export function getListAPI(params) {
  return http.get(`${resquest}/getList.json`, params);
}
// 请求 URL 地址
export function getFindByClsNum(params) {
  return http.post(`${resquest}/video/findByClsNum`, params);
}
//登录接口
export function getLogin(params) {
  return http.post(`${resquest}/ExternalApi/fbTeacherLogin`, params);
}

//获取该老师的课程列表，自己去判定时间
export function getCourseList(params) {
  return http.post(`${resquest}/ExternalApi/getCourseList`, params);
}

//通过课程id的数组获取课程们的开始结束时间
export function getCourseDataTimeByIds(params) {
  return http.post(`${resquest1}/CourseConfig/getCourseConfigList`, params);
}

//通过班主任id获取课程设置
export function getCourseConfByAssistant(params) {
  return http.post(`${resquest1}/CourseConfig/getConfigByAssistant`, params);
}

// 获取班课接口
export function getClassRoom(params) {
  return http.post(`${resquest}/ClassroomNum/classroomNumList`, params);
}
// 获取答疑课接口
export function getanswering(params) {
  return http.post(`${resquest}/LessonNumber/getLessonNumber1`, params);
}

// 请求上课地址
export function getInClassRoom(params) {
  return http.post(`${resquest}/TalkCloudCourse/getCourseUrl`, params);
}
// 请求讲义名称
export function getBindInfo(params) {
  return http.post(`${resquest2}/HandoutBind/getBindInfo`, params);
}

// 学情报告
export function getAnalysisUrl(params) {
  return http.post(`${resquest}/TalkCloudCourse/getAnalysisUrl`, params);
}

// 根据班主任获取课程配置信息
export function getCourseConfig(params) {
  return http.post(`${resquest1}/CourseConfig/getCourseConfigList`, params);
}
