/** **   request.js   ****/
// 导入axios
import axios from "axios";
import qs from "qs";
// 使用element-ui Message做消息提醒
import { Message } from "element-ui";
import { Base64 } from "js-base64";
// 1. 创建新的axios实例，
const service = axios.create({
  // 公共接口
  baseURL: process.env.NODE_ENV === "production" ? "" : "",
  // 超时时间
  timeout: 3 * 10000,
});
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    const newConfig = config;
    // console.log(newConfig,'newConfig')
    for (const data in newConfig.data) {
      if ({}.hasOwnProperty.call(newConfig.data, data)) {
        if (newConfig.data[data] == null) continue;
        if (typeof newConfig.data[data] !== "string") {
          newConfig.data[data] = JSON.stringify(newConfig.data[data]);
        }
        newConfig.data[data] = Base64.encode(newConfig.data[data]);
      }
    }
    newConfig.data = qs.stringify(newConfig.data);
    // console.log(newConfig,'newConfigend')
    return newConfig;
  },
  (error) => Promise.error(error)
);
// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    // 接收到响应数据并成功后的一些共有的处理，关闭loading等

    return response;
  },
  (error) => {
    /** *** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          // window.location.href = '/NotFound'
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        Message.error("服务器响应超时，请刷新当前页");
      }
      // Message.error('连接服务器失败')
    }

    // Message.error(error.message)
    /** *** 处理结束 *****/
    // 如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response);
  }
);
// 4.导入文件
export default service;
